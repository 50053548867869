let apiClient

export function setApiClient(apiInstance) {
  apiClient = apiInstance;
}
export class BaseService {
  apiClient() {
    return apiClient
  }
}
