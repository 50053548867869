export default function ({redirect, app, req, i18n, route, store}) {
  store.commit('setDomain', req?.headers?.host)
  const myRoute = process.env.webUrl
  // if (req?.headers?.host === 'eznovels.com' || req?.headers?.host === 'lightnoveldaily.com') {
  //   app.i18n.defaultLocale = 'es'
  // } else {
  //   app.i18n.defaultLocale = 'en'
  // }
  const blockWebsite = [
    "gwingkee.com.hk"
  ]
  if (blockWebsite.includes(req?.headers?.host)) {
    return redirect(myRoute + route.path)
  }
}
