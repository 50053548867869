//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Login from '@/components/Login/index.vue'
import { storyService } from '@/services'
import { mapState } from 'vuex'
import { debounce, size, map, find } from 'lodash'
export default {
  // components: { Login },
  data () {
    return {
      isDropped: false,
      isDroppedLanguage: false,
      isShowLoginForm: false,
      languages: [
        {name: 'English', locale: 'en'},
        {name: 'Russia', locale: 'ru'},
        {name: 'Spain', locale: 'es'},
        {name: 'Portugal', locale: 'pt'},
        {name: 'Thailand', locale: 'th'}
      ],
      isCheck: '',
      searchKey: '',
      searchResult: [],
      showSearchResult: false,
      isShowHamburgerTab: false,
      mail: 'hotnovelpub@gmail.com',
      webName: process.env.webName,
    }
  },
  // mounted () {
  //   if (size(this.listCategory) === 0) {
  //     this.getListCategory()
  //   }
  // },
  // async fetch () {
  //   if (size(this.listCategory) === 0) {
  //     await this.getListCategory()
  //   }
  // },
  computed: {
    ...mapState({
      listCategory: (state) => state.listCategory || []
    })
  },
  mounted () {
    if (size(this.listCategory) === 0) {
      this.getListCategory()
    }
    if (this.webName === 'ClubNovels') {
      document.documentElement.setAttribute('data-site', 'club');
    }

    if (this.webName === 'NowNovels') {
      document.documentElement.setAttribute('data-site', 'now');
    }

    if (localStorage.getItem('theme')) {
      document.documentElement.setAttribute('data-theme', localStorage.getItem('theme'));
    } else {
      localStorage.setItem('theme', 'light');
    }
    this.isCheck = localStorage.getItem('theme') || 'light'
    // if (localStorage.getItem('language')) {
    //   // this.$i18n.locale = localStorage.getItem('language')
    // } else {
    //   // localStorage.setItem('language', 'en');
    // }
  },
  methods: {
    dropCategory () {
      this.isDropped = !this.isDropped
    },
    dropLanguage() {
      this.isDroppedLanguage = !this.isDroppedLanguage
    },
    switchTheme(e) {
      if (e.target.checked) {
        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem('theme', 'light');
      }
      location.reload();
    },
    handleOpenLoginForm () {
      this.isShowLoginForm = !this.isShowLoginForm
    },
    switchLanguage(lang) {
      // this.$i18n.locale = lang
      this.$i18n.setLocale(lang);
      // localStorage.setItem('language', this.$i18n.locale);
    },
    debounceSearchStory: debounce(function() {
      if (this.searchKey.length >= 1) {
        this.searchStory(this.searchKey)
      } else {
        this.showSearchResult = false
      }
    }, 500),
    removeSearch () {
      this.searchKey = ''
      this.searchResult = []
      this.showSearchResult = false
    },
    async searchStory(searchKey) {
      try {
        const requestData = {
          key_search: searchKey
        }
        let searchResult = []
        const response = await storyService.searchStory(requestData)
        if (response.status) {
          const dataResponse = response.data
          let authorizes = []
          let stories = []
          if (size(dataResponse.authorizes) > 0) {
            authorizes = map(dataResponse.authorizes, item => ({
              ...item,
              type: 'author'
            }))
          }
          if (size(dataResponse.books) > 0) {
            stories = map(dataResponse.books, item => ({
              ...item,
              type: 'story'
            }))
          }
          searchResult = [...stories, ...authorizes]
        }
        this.searchResult = searchResult
        this.showSearchResult = true
      } catch (error) {
        console.log(error)
      }
    },
    handleCloseSearchResult () {
      this.showSearchResult = false
    },
    async getListCategory () {
      try {
        const response = await storyService.getListCategory(this.$route.params.slug)
        if (response.status) {
          this.$store.commit('saveListCategory', response.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleCloseLanguage () {
      this.isDroppedLanguage = false
    },
    handleCloseCategory () {
      this.isDropped = false
    },
    handleCloseHumburgerTab () {
      this.isShowHamburgerTab = false
    },
    handleShowHumburgerTab () {
      this.isShowHamburgerTab = !this.isShowHamburgerTab
    },
    goToWeb(lang) {
      const locale = find(this.$i18n.locales, item => item.code === lang)
      if (locale) {
        window.location.href = locale.webUrl
      }
    }
  }
}
