export default {
  saveListCategory (state, data) {
    state.listCategory = data
  },

  saveListAuthorize (state, data) {
    state.listAuthorize = data
  },

  saveListHotStory (state, data) {
    state.listHotStory = data
  },

  saveListFullStory (state, data) {
    state.listFullStory = data
  },

  saveListNewStory (state, data) {
    state.listNewStory = data
  },

  setDomain(state, domain) {
    state.domain = domain;
  }
}
