//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['error'],

  data () {
    return {
      metaTitle: this.$t('metaTitle')
    }
  },

  head() {
    return {
      title: `${process.env.webName} - ${this.metaTitle}`,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=5, minimal-ui' },
        { property: 'description', name: 'description', content: this.metaTitle},
        { name: 'format-detection', content: 'telephone=no' },
        { name: 'apple-mobile-web-app-capable', content: 'yes'},
        {
          property: 'og:title',
          name: 'og:title',
          content: `${process.env.webName} - ${this.metaTitle}`,
        },
        {
          property: 'og:site_name',
          name: 'og:site_name',
          content: `${process.env.webName} - ${this.metaTitle}`,
        },
        {
          property: 'og:description',
          name: 'og:description',
          content: this.metaTitle
        }
      ],
    }
  },

  mounted() {
    this.$router.push('/404');
  }
}
