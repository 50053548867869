const plugin = ({ $axios, store, redirect, error }, inject) => {
  const api = $axios.create({
    baseURL: process.env.apiUrl,
    headers: {
      lang: process.env.language,
    },
  })

  api.onResponse((response) => {
    return response
  })

  api.onError((errorr) => {
    if (errorr.response.status === 301) {
      if (errorr.response.data?.data?.slug) {
        redirect(`/${errorr.response.data.data.slug}`)
      } else {
        redirect('/')
      }
    } else if (errorr.response.status === 404) {
      error({ statusCode: errorr.response.status, message: 'Post not found' })
      redirect('/404')
      return Promise.resolve(false)
    } else {
      console.log('errorr', errorr)
      // redirect('/404')
    }
  })

  inject('apiClient', api)
}

export default plugin
