import { BaseService } from './baseService'

class StoryService extends BaseService {

  async getListCategory () {
    const res = await this.apiClient().get('categories')
    return res.data
  }

  async getListStoryByType (type, param) {
    const res = await this.apiClient().get(`books/${type}`, {
      params: param
    })
    return res.data
  }

  async getListStoryByCategory (category, param) {
    const res = await this.apiClient().get(`category/${category}`, {
      params: param
    })
    return res.data
  }

  async getListBookByAuthor (author, param) {
    const res = await this.apiClient().get(`authorize/${author}`, {
      params: param
    })
    return res.data
  }

  async getBookInfo (book) {
    const res = await this.apiClient().get(`book/${book}`)
    return res.data
  }

  async getChapterContent (chapter) {
    const res = await this.apiClient().get(`chapter-content/${chapter}`)
    return res.data
  }

  async getChapterInfo (chapter) {
    const res = await this.apiClient().get(`chapter/${chapter}`)
    return res.data
  }

  async getListChapter (chapter) {
    const res = await this.apiClient().get(`chapters/${chapter}`)
    return res.data
  }

  async getListAuthor () {
    const res = await this.apiClient().get('authorizes')
    return res.data
  }

  async searchStory (data) {
    const res = await this.apiClient().post('search', data)
    return res.data
  }

  async getNewStoryUpdate (param) {
    const res = await this.apiClient().get('books/new-update', {
      params: param
    })
    return res.data
  }

  async getListStoryRecommend (storySlug) {
    const res = await this.apiClient().get(`books/recommend/${storySlug}`)
    return res.data
  }

  async getListStorySitemap () {
    const res = await this.apiClient().get(`sitemap-book`)
    return res.data
  }

  async rateBook (data) {
    const res = await this.apiClient().post('books/rate', data)
    return res.data
  }

  async reportBook (data) {
    const res = await this.apiClient().post('book/report', data)
    return res.data
  }
}

export const storyService = new StoryService()
