import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _59839b84 = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _efb727d6 = () => interopDefault(import('../pages/author/index.vue' /* webpackChunkName: "pages/author/index" */))
const _60600408 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _224be60e = () => interopDefault(import('../pages/sitemapp.xml/index.vue' /* webpackChunkName: "pages/sitemapp.xml/index" */))
const _5b984390 = () => interopDefault(import('../pages/author/_slug/index.vue' /* webpackChunkName: "pages/author/_slug/index" */))
const _41de4d7a = () => interopDefault(import('../pages/category/_slug/index.vue' /* webpackChunkName: "pages/category/_slug/index" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7f964680 = () => interopDefault(import('../pages/_story/index.vue' /* webpackChunkName: "pages/_story/index" */))
const _8fa6d1da = () => interopDefault(import('../pages/_story/_chapter/index.vue' /* webpackChunkName: "pages/_story/_chapter/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _59839b84,
    name: "404"
  }, {
    path: "/author",
    component: _efb727d6,
    name: "author"
  }, {
    path: "/category",
    component: _60600408,
    name: "category"
  }, {
    path: "/sitemapp.xml",
    component: _224be60e,
    name: "sitemapp.xml"
  }, {
    path: "/author/:slug",
    component: _5b984390,
    name: "author-slug"
  }, {
    path: "/category/:slug",
    component: _41de4d7a,
    name: "category-slug"
  }, {
    path: "/",
    component: _1479741b,
    name: "index"
  }, {
    path: "/:story",
    component: _7f964680,
    name: "story"
  }, {
    path: "/:story/:chapter",
    component: _8fa6d1da,
    name: "story-chapter"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
