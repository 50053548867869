//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      mail: 'hotnovelpub@gmail.com',
      webUrl: process.env.webName
    }
  }
}
